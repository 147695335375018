import React from "react";
import { GalliMapProvider } from "./galliMapProvider";
import { GalliMapModal } from "./GalliMapModal";
import { DEFAULT_FALLBACK_LOCATION, GALLIMAP_ACCESS_TOKEN } from "../../constants";

export const GalliMap = ({ onClose, onConfirm, initialLocation }) => {
  return (
    <GalliMapProvider
      initialLocation={
        initialLocation || {
          lat: DEFAULT_FALLBACK_LOCATION?.[0],
          lng: [DEFAULT_FALLBACK_LOCATION?.[1]],
        }
      }
      accessToken={GALLIMAP_ACCESS_TOKEN}
    >
      <GalliMapModal
        accessToken={GALLIMAP_ACCESS_TOKEN}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </GalliMapProvider>
  );
};
