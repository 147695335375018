import React from 'react';
import { ContentLoader } from '../ContentLoader';
import { LocationIcon } from '../../../icons';

export const SuggestionListSkeleton = () => {
  return (
<div style={{width: "100%"}}>
      {[...Array(3)].map((_, index) => (
        <li key={index} className="suggestion-item-loader">
            <LocationIcon className="suggestion-icon" />
          <ContentLoader
            speed={2}
            width="100%"
            height="30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="5" y="5" rx="5" ry="5" width="60%" height="18" /> 
          </ContentLoader>
        </li>
      ))}
    </div>
  );
};
