/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import {
  getReverseGeocodingData,
  searchLocation,
} from "../../api/galliMaps/galliMaps";
import { loadGalliScript } from "./utils";
import { DEFAULT_FALLBACK_LOCATION } from "../../constants";

const GalliMapContext = createContext();

export const useGalliMap = () => {
  const context = useContext(GalliMapContext);
  if (!context) {
    throw new Error("useGalliMap must be used within a GalliMapProvider");
  }
  return context;
};

export const GalliMapProvider = ({
  accessToken,
  enableClick = true,
  showInfoBanner = false,
  initialLocation,
  children,
}) => {
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isFlying, setIsFlying] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    initialLocation || null
  );
  const [mapError, setMapError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  /**
   * Initialize GalliMap script once
   */
  useEffect(() => {
    if (window.GalliMapPlugin) {
      initializeMap();
      return;
    }

    if (!document.getElementById("galli-map-script")) {
      const script = document.createElement("script");
      script.id = "galli-map-script";
      loadGalliScript(script, initializeMap, () => {
        setMapError("Failed to load Galli Maps. Please try again later.");
        setIsLoading(false);
      });
    }
  }, []);

  /**
   * Initialize Map Callback
   */
  const initializeMap = useCallback(() => {
    if (!window.GalliMapPlugin || mapRef.current) return;

    try {
      mapRef.current = new window.GalliMapPlugin({
        accessToken,
        map: {
          container: "map",
          center: initialLocation
            ? [initialLocation.lat, initialLocation.lng]
            : DEFAULT_FALLBACK_LOCATION,
          zoom: 9,
          maxZoom: 25,
          minZoom: 5,
          draggable: enableClick,
          clickable: enableClick,
        },
        pano: { container: "pano" },
      });

      if (markerRef?.current) {
        markerRef.current.setLngLat(
          initialLocation?.lat
            ? [initialLocation.lat, initialLocation.lng]
            : DEFAULT_FALLBACK_LOCATION
        );
      } else {
        markerRef.current = mapRef.current.displayPinMarker({
          color: "#FF0000",
          draggable: enableClick,
          latLng: initialLocation?.lat
            ? [initialLocation.lat, initialLocation.lng]
            : DEFAULT_FALLBACK_LOCATION,
        });
      }

      mapRef.current.map.on("click", handleMapClick);

      mapRef.current.map.on("dragend", handleMarkerDrag);
      setIsLoading(false);
    } catch (error) {
      console.error("Map initialization error:", error);
      setMapError("Failed to initialize map. Please check your access token.");
      setIsLoading(false);
    }
  }, [accessToken, initialLocation]);

  /**
   * Select Location with Fly-to Animation
   */
  const { mutate: selectLocation, isLoading: isSelectLocationLoading } =
    useMutation(
      async ({ accessToken, location }) => {
        return await searchLocation(accessToken, { name: location?.name });
      },
      {
        onSuccess: (response, { location }) => {
          const feature = response.data.data.features?.find(
            (ite) => ite?.geometry?.type === "Point"
          );
          const { coordinates } = feature?.geometry ?? { coordinates: [] };

          setSelectedLocation({
            lat: coordinates[1],
            lng: coordinates[0],
            name: location?.name,
          });

          setSearchText(location?.name);
          setSuggestions([]);

          // Avoid unnecessary re-creation of markers

          markerRef.current.setLngLat([coordinates[0], coordinates[1]]);

          setIsFlying(true);
          requestAnimationFrame(() => {
            mapRef.current.map.flyTo({
              center: [coordinates[0], coordinates[1]],
              zoom: 16,
              duration: 4000,
            });
          });

          setTimeout(() => setIsFlying(false), 4000);
        },
      }
    );

  /**
   * Reverse Geocoding
   */
  const { refetch, isLoading: isReverseGeocodingLoading } = useQuery(
    ["reverseGeocoding"],
    async () =>
      await getReverseGeocodingData(accessToken, {
        lat: selectedLocation?.lat || DEFAULT_FALLBACK_LOCATION?.[0],
        lng: selectedLocation?.lng || DEFAULT_FALLBACK_LOCATION?.[1],
      }),
    {
      retry: 1,
      onSuccess: (response) => {
        const locationName =
          response?.pois?.location ??
          response?.generalName ??
          "Unknown Location";
        setSearchText(locationName);
        setSelectedLocation((prev) => ({
          ...prev,
          name: locationName,
        }));
      },
    }
  );

  /**
   * Handle Map Click
   */
  const handleMapClick = (event) => {
    if (!enableClick) return;
    const { lng, lat } = event.lngLat;
    setSelectedLocation({ lat, lng });

    markerRef.current.setLngLat([lng, lat]);

    setIsFlying(true);

    mapRef.current.map.flyTo({
      center: [lng, lat],
      zoom: 15,
      duration: 2000,
    });

    setTimeout(() => setIsFlying(false), 2000);

    // Trigger reverse geocoding to get location name
    refetch();
  };

  /**
   * Handle Marker Drag
   */
  const handleMarkerDrag = () => {
    if (!enableClick) return;
    const position = markerRef.current.getLngLat();
    setSelectedLocation({ lat: position.lat, lng: position.lng });
    refetch();
  };

  // Memoize Context Value to optimizing re-renders
  const contextValue = useMemo(
    () => ({
      searchText,
      accessToken,
      showInfoBanner,
      setSearchText,
      selectLocation,
      isFlying,
      suggestions,
      setSuggestions,
      isSelectLocationLoading:
        isSelectLocationLoading || isFlying || isReverseGeocodingLoading,
      selectedLocation,
      setSelectedLocation,
      isLoading,
      setIsLoading,
      setIsFlying,
      mapError,
      setMapError,
      mapRef,
      markerRef,
    }),
    [
      searchText,
      accessToken,
      showInfoBanner,
      selectLocation,
      isFlying,
      suggestions,
      isSelectLocationLoading,
      isReverseGeocodingLoading,
      selectedLocation,
      isLoading,
      markerRef?.current?.getLngLat(),
      mapError,
    ]
  );

  return (
    <GalliMapContext.Provider value={contextValue}>
      {children}
    </GalliMapContext.Provider>
  );
};
