import ContentLoader from "react-content-loader";
import { randomArray, uid } from "../../../utils";

export const InfoBannerSkeleton = (props) => {
  const { numOfArray = 1, ...rest } = props;
  return (
    <>
      {randomArray(numOfArray).map((item) => (
        <div className="res-loader res-loader-info-banner" key={uid()}>
          <ContentLoader
            speed={2}
            width={"100%"}
            height={"60px"}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...rest}
          >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="20" />
            <rect x="0" y="40" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        </div>
      ))}
    </>
  );
};
