import { useMutation } from "react-query";
import { popToast } from "../components/common/PopToast";
import { boostProductAd } from "../api/boostAdService";

export const SendMailService = (reset) => {
  const { mutate, isLoading, data } = useMutation(
    async (requestData) => {
      const response = await boostProductAd(requestData);

      if (!response?.status?.status) {
        return popToast(
          "Sorry, we are unable to connect. Please try again.",
          "TOP_RIGHT"
        );
      }

      return response;
    },
    {
      onSuccess: (response) => {
        popToast(
          response?.status?.message?.[0] ?? "Thank your for boosting with us. We will contact you soon.",
          "TOP_RIGHT"
        );
        reset();
      },
      onError: () => {
        popToast(
          "Sorry, we are unable to connect. Please try again.",
          "TOP_RIGHT"
        );
      },
    }
  );

  return { mutate, isLoading, data };
};
