import { axiosInstance } from "../../utils";

export const getReverseGeocodingData = async (accessToken, meta_data) => {
  try {
    const response = await axiosInstance().get(
      `https://route-init.gallimap.com/api/v1/reverse/generalReverse?accessToken=${accessToken}&lat=${meta_data?.lat}&lng=${meta_data?.lng}`
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Reverse Geocoding Error:", error);
  }
};

export const getAutoCompleteSuggestions = async (accessToken, query) => {
  try {
    const response = await axiosInstance().get(
      `https://route-init.gallimap.com/api/v1/search/autocomplete?accessToken=${accessToken}&word=${query}&lat=27.7&lng=85.3`
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Fetching Suggestion Error:", error);
  }
};

export const searchLocation = async (accessToken, location) => {
  try {
    const response = await axiosInstance().get(
      `https://route-init.gallimap.com/searchApi/geojson/currentLocation?accessToken=${accessToken}&name=${encodeURIComponent(
        location?.name
      )}&currentLat=27.7&currentLng=85.3`
    );
    return response;
  } catch (error) {
    console.error("Search API Error:", error);
  }
};

export const fetchAutocompleteSuggestionsWithMapInstance = async (
  map,
  searchText
) => {
  try {
    if (!map || typeof map.autoCompleteSearch !== "function") {
      console.error("Invalid map object provided.");
      return;
    }
    const result = await map.autoCompleteSearch(searchText);
    return result;
  } catch (error) {
    console.error("Error fetching autocomplete suggestions:", error);
  }
};
