/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery } from "react-query";
import {
  fetchAutocompleteSuggestionsWithMapInstance,
  getAutoCompleteSuggestions,
} from "../../api/galliMaps/galliMaps";
import { useClickOutside } from "../../hooks/useClickOutside";
import { CloseIcon, LocationIcon, NormalSearchIcon } from "../../icons";
import { useGalliMap } from "./galliMapProvider";
import { debounce } from "lodash";
import { SuggestionListSkeleton } from "../skeletons/map/suggestionsSkeletion";
import Loader from "../skeletons/Loader";

export const GalliMapHeader = () => {
  const {
    searchText,
    setSearchText,
    setSuggestions,
    accessToken,
    mapRef,
    isSelectLocationLoading,
    selectLocation,
  } = useGalliMap();

  const [inputText, setInputText] = useState("");
  const searchInputRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { data: suggestions, isLoading } = useQuery(
    ["GALLI_MAP_AUTOCOMPLETE", searchText],
    () =>
      mapRef?.current
        ? fetchAutocompleteSuggestionsWithMapInstance(
            mapRef?.current,
            searchText
          )
        : getAutoCompleteSuggestions(accessToken, searchText),
    {
      enabled: searchText?.trim().length >= 3 && showSuggestions,
      onSuccess: (data) => setSuggestions(data),
    }
  );

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchText(query);
      setSuggestions([]);
    }, 300),
    []
  );

  useEffect(() => {
    setInputText(searchText);
  }, [searchText]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setInputText(query);
    if (query?.trim().length >= 3) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
    debouncedSearch(query);
  };

  const handleClearSearch = () => {
    setInputText("");
    setSearchText("");
    setShowSuggestions(false);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleSelectLocation = (location) => {
    setShowSuggestions(false);
    selectLocation({ accessToken, location });
  };

  const dropdownRef = useClickOutside(() => setShowSuggestions(false));

  return (
    <div className="search-container" ref={dropdownRef}>
      <div className="search-input-wrapper">
        <NormalSearchIcon className="search-icon" />
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search for a location..."
          value={inputText}
          onChange={handleSearchChange}
          className="search-input"
        />
        {!isSelectLocationLoading ? (
          inputText && (
            <button
              onClick={handleClearSearch}
              className="clear-search-button close"
              aria-label="Clear search"
            >
              <CloseIcon />
            </button>
          )
        ) : (
          <button
            onClick={handleClearSearch}
            className="autocomplete-loader"
            aria-label="Clear search"
          >
            <Loader />
          </button>
        )}
      </div>

      {showSuggestions && (
        <ul className="suggestions-list">
          {isLoading ? (
            <SuggestionListSkeleton />
          ) : (
            suggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelectLocation(suggestion)}
                className="suggestion-item"
              >
                <LocationIcon className="suggestion-icon" />
                <span>{suggestion.name}</span>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};
