import React from "react";
import DropdownSeparator from "rsuite/esm/Dropdown/DropdownSeparator";
import { LocationIcon } from "../../icons";

const AdderessInfoBanner = ({
  selectedLocation,
  infoBannerTitle,
  onActionClick,
  location,
}) => {
  return (
    <div className="info-banner">
      <div className="info-content">
        {infoBannerTitle && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="location-name">{infoBannerTitle}</div>
            <button
              onClick={() => onActionClick?.()}
              className="marker-show-button"
            >
             <LocationIcon/>
            </button>
          </div>
        )}
        <DropdownSeparator />

        <div className="location-name">{selectedLocation.name}</div>
        <div className="location-coordinates">
          {selectedLocation.lat && selectedLocation.lng
            ? `Latitude: ${selectedLocation.lat}`
            : `Latitude: ${location?.coordinates?.lat}`}
        </div>
        <div className="location-coordinates">
          {selectedLocation.lat && selectedLocation.lng
            ? `Longitude: ${selectedLocation.lng}`
            : `Longitude: ${location?.coordinates?.lng}`}
        </div>
      </div>
    </div>
  );
};

export default AdderessInfoBanner;
