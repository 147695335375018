import { CloseIcon } from "../../icons";
import { GalliMapContainer } from "./GalliMapContainer";
import { GalliMapHeader } from "./GalliMapHeader";
import { useGalliMap } from "./galliMapProvider";

export const GalliMapModal = ({ onClose, onConfirm }) => {
  const { selectedLocation, searchText, isSelectLocationLoading } =
    useGalliMap();

  return (
    <div className="galli-map-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Select Location</h2>
          <button onClick={onClose} className="close-button" aria-label="Close">
            <CloseIcon color="black" />
          </button>
        </div>
        <GalliMapHeader />
        <GalliMapContainer />
        <div className="modal-footer">
          <div className="action-buttons">
            <button onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button
              type="button"
              onClick={() => onConfirm(selectedLocation, searchText)}
              disabled={
                !selectedLocation || isSelectLocationLoading || !searchText
              }
              className={`confirm-button ${
                !selectedLocation ? "disabled" : ""
              }`}
            >
              Confirm Location
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
