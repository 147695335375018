/* eslint-disable max-len */
import { useCallback, useEffect, useState } from "react";

import {
  AUTOCOMPLETE_SUGGESSTIONS_URL,
  GALLIMAP_ACCESS_TOKEN,
} from "../../constants";
import { useClickOutside, useGeoLocation, useSearch } from "../../hooks";

import { useMutation } from "react-query";
import { searchLocation } from "../../api/galliMaps/galliMaps";
import { ReactComponent as GpsIcon } from "../../icons/common/gps.svg";
import { GalliMap } from "../galliMaps/GalliMap";

/**
 * It takes a search term, makes a request to the server, and returns the results
 */
const SearchLocation = ({
  inSearchBar,
  label,
  onmapchange,
  formError,
  location: loc,
  setLocationValue,
  onChangeLocationPicker,
  resetLocation,
  setResetLocation,
}) => {
  const [searchParams, setSearchParams] = useState("");
  const [value, setValue] = useState(loc?.locationDescription ?? "");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [userPinned, setUserPinned] = useState(false);

  const location = useGeoLocation();

  // eslint-disable-next-line no-unused-vars
  const [selectedLocation, setSelectedLocation] = useState({
    locationDescription: loc?.locationDescription ?? "",
    locationLatitude: loc?.locationLatitude ?? 0,
    locationLongitude: loc?.locationLongitude ?? 0,
  });
  useEffect(() => {
    setResetLocation && setResetLocation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    resetLocation && setValue("");
  }, [resetLocation]);

  const deleteSearchLatLng = useCallback(() => {
    localStorage.removeItem("searchBarLatitude");
    localStorage.removeItem("searchBarLongitude");
  }, []);

  const onchange = (e) => {
    setSearchParams(e.target.value.replace(/[^a-zA-Z0-9 ]/gm, ""));
    setValue(e.target.value);
    setShowSuggestions(true);
    if (selectedLocation.locationDescription !== "") {
      setSelectedLocation({
        locationDescription: "",
        locationLatitude: 0,
        locationLongitude: 0,
      });
    }

    if (e.target.value?.length === 0) {
      let pack = {
        locationLatitude: 0,
        locationLongitude: 0,
        locationDescription: "",
      };
      onmapchange && onmapchange(pack, "location");

      // Save the lat and lng in local storage if the location data is from search field
      if (inSearchBar) deleteSearchLatLng();

      setUserPinned(false); // setUserPinned to false after search is cleared.

      // When ever the location is changed the local Storage lng and lat is change by this function
      setLngAndLatInLocalStorage(pack.locationLatitude, pack.locationLongitude);
    }
  };

  const { data } = useSearch(
    "LOCATION_SEARCH",
    AUTOCOMPLETE_SUGGESSTIONS_URL,
    `accessToken=${GALLIMAP_ACCESS_TOKEN}&lat=27.7&lng=85.3&word`,
    searchParams
  );

  const { mutate: selectLocation } = useMutation(
    async (clickSuggestion) => {
      return await searchLocation(GALLIMAP_ACCESS_TOKEN, {
        name: clickSuggestion?.name,
      });
    },
    {
      onSuccess: (response, clickSuggestion) => {
        const feature = response.data.data.features?.find((ite) => {
          return ite?.geometry?.type === "Point";
        });
        const { coordinates } = feature?.geometry ?? {coordinates: []};

        setValue(clickSuggestion.name);

        setSearchData({
          longV: coordinates?.[0],
          latV: coordinates?.[1],
          locationName: clickSuggestion?.name,
        });
        setSelectedLocation({
          locationLatitude: coordinates?.[1],
          locationLongitude: coordinates?.[0],
          locationDescription: clickSuggestion?.name,
        });
      },
      onError: (error) => {
        console.error("Error fetching location data:", error);
      },
    }
  );

  /**
   * It takes a clickSuggestion object as an argument,
   * and then it sets the latitude and longitude in
   * local storage.
   */
  const handleSelect = (clickSuggestion) => {
    selectLocation(clickSuggestion);
  };

  const renderSuggestions = () => {
    if (!data?.data || data?.data.length === 0) return null; // Avoid rendering empty UI

    return (
      <ul>
        {data.data.map((suggestion) => (
          <li key={suggestion.id} onClick={() => handleSelect(suggestion)}>
            <strong>{suggestion.nameLower}</strong>{" "}
            <small>
              {suggestion.municipality} {suggestion.district}
            </small>
          </li>
        ))}
      </ul>
    );
  };

  const setLngAndLatInLocalStorage = (lat, lng, search) => {
    if (search) {
      localStorage.setItem("searchBarLatitude", lat);
      localStorage.setItem("searchBarLongitude", lng);
    }
  };
  const getLocation = (loca, address) => {
    const { lat, lng } = loca;
    setValue(address);
    let pack = {
      locationLatitude: lat,
      locationLongitude: lng,
      locationDescription: address,
    };
    onmapchange && onmapchange(pack, "location");
    onChangeLocationPicker &&
      onChangeLocationPicker(() => {
        setTimeout(() => setLocationValue("location", pack), 0);
      });
    if (inSearchBar)
      setLngAndLatInLocalStorage(
        pack.locationLatitude,
        pack.locationLongitude,
        true
      );
  };

  /* A custom hook that is used to detect click outside of the component and when location is selected then first location is selecteda default */
  const registerRef = useClickOutside(() => {
    defaultFirstSuggestedLocation();
  });

  function defaultFirstSuggestedLocation() {
    if (
      selectedLocation.locationLatitude === 0 &&
      selectedLocation.locationLongitude === 0
    ) {
      if (!userPinned) {
        if (data?.data?.data?.[0]) {
          const clickSuggestion = data?.data?.data?.[0];

          setSearchData(clickSuggestion);
        }
      }
    }
  }

  const setSearchData = (searchData) => {
    let pack = {
      locationLatitude: searchData?.latV,
      locationLongitude: searchData?.longV,
      locationDescription: searchData?.locationName,
    };
    onmapchange && onmapchange(pack, "location");
    onChangeLocationPicker &&
      onChangeLocationPicker(() => {
        setTimeout(() => setLocationValue("location", pack), 0);
      });
    setValue(searchData.locationName);
    /* Setting the latitude and longitude in local storage. */
    if (inSearchBar)
      setLngAndLatInLocalStorage(
        pack.locationLatitude,
        pack.locationLongitude,
        true
      );
    /* Setting the latitude and longitude in local storage. */
    setLngAndLatInLocalStorage(pack.locationLatitude, pack.locationLongitude);
    setShowSuggestions(false);
  };

  let domNode = useClickOutside(() => {
    setShowSuggestions(false);
  });

  return (
    <>
      <div
        className={`input--wrapper ${
          !inSearchBar ? "pos-rel" : "searchbar--input-wrapper"
        } ${value !== "" ? "show--label" : ""}`}
        ref={registerRef}
      >
        <input
          name="location"
          value={value || ""}
          placeholder="Entire Nepal"
          className={`input place--lister ${inSearchBar ? "searchbar" : ""} ${
            formError && formError.locationText ? "required-error" : ""
          }`}
          onChange={(e) => onchange(e)}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              defaultFirstSuggestedLocation();
              setShowSuggestions(false);
            }
          }}
          autoComplete="off"
        />

        {!userPinned && showSuggestions && (
          <div
            className={`place--suggestions ${inSearchBar ? "searchbar" : ""} ${
              data?.length > 0 ? "place--suggestions--showBorder" : ""
            }`}
          >
            <ul ref={domNode}>{renderSuggestions()}</ul>
          </div>
        )}
        {!inSearchBar && (
          <label className="the--label">{label || "Location"}</label>
        )}
        <span
          className={`gps--address ${inSearchBar ? "searchbar" : ""}`}
          onClick={() => setShowLocation(true)}
        >
          {inSearchBar ? (
            <>
              <i className="fal fa-map-marker-alt desktop--only"></i>
              <i className="fal fa-map-marker-alt mobile--only"></i>
            </>
          ) : (
            <GpsIcon className="ab__svg" />
          )}
        </span>
      </div>
      {formError && formError?.locationText && (
        <small className="input--message input--message--no-margin warning">
          Pleasae add location through map picker or given location suggestions.
        </small>
      )}
      {showLocation && (
        <GalliMap
          initialLocation={
            loc
              ? {
                  lat: loc?.locationLatitude ?? location.coordinates,
                  lng: loc?.locationLongitude ?? location.coordinates,
                }
              : location.coordinates
          }
          onConfirm={(loca, address) => {
            getLocation(loca, address);
            setShowLocation(false);
          }}
          onClose={() => {
            setShowLocation(false);
          }}
        />
      )}
    </>
  );
};

export default SearchLocation;
