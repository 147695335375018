/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useGeoLocation } from "../../hooks";
import SmallLoader from "../loaders/SmallLoader";
import { InfoBannerSkeleton } from "../skeletons/map/InforBannerSkeletion";
import AdderessInfoBanner from "./AdderessInfoBanner";
import { useGalliMap } from "./galliMapProvider";

export const GalliMapContainer = ({ infoBannerTitle }) => {
  const {
    isLoading,
    mapError,
    mapRef,
    showInfoBanner,
    isSelectLocationLoading,
    selectedLocation,
    setIsFlying,
  } = useGalliMap();

  const mapContainerRef = useRef(null);
  const panoContainerRef = useRef(null);
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  /**
   * Ensure Map is Rendered
   */
  const ensureMapRendered = (updateLocation = true) => {
    if (mapRef.current && mapRef.current.map) {
      mapRef.current.map.resize();
      if (selectedLocation) {
        updateLocation && setIsFlying(true);
        mapRef.current.map.flyTo({
          center: [selectedLocation.lng, selectedLocation.lat],
          zoom: 15,
          duration: 4000,
        });

        timeoutRef.current = setTimeout(() => {
          updateLocation && setIsFlying(false);
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (!isLoading && !mapError) {
      ensureMapRendered();
    }

    // Cleanup function to clear timeout when component unmounts or dependencies change
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading, mapError]);

  const location = useGeoLocation();
  return (
    <div className="map-container">
      <div className="map-wrapper">
        {isLoading && (
          <div className="loading-overlay">
            <SmallLoader className="loading-icon" />
          </div>
        )}

        {mapError && (
          <div className="error-overlay">
            <div className="error-message">
              <p>Error loading map</p>
              <p>{mapError}</p>
            </div>
          </div>
        )}

        <div ref={mapContainerRef} id="map" className="map"></div>
        <div ref={panoContainerRef} id="pano" className="panorama"></div>

        {!isLoading && isSelectLocationLoading && showInfoBanner ? (
          <div className="info-banner">
            <InfoBannerSkeleton />
          </div>
        ) : (
          !isLoading &&
          !isSelectLocationLoading &&
          showInfoBanner &&
          selectedLocation && (
            <AdderessInfoBanner
              infoBannerTitle={infoBannerTitle}
              selectedLocation={selectedLocation}
              location={location}
              onActionClick={() => ensureMapRendered(false)}
            />
          )
        )}
      </div>
    </div>
  );
};
